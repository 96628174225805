import {TwoColumn} from '@/components/base/layouts/TwoColumn/TwoColumn';
import type {HeadingGroupProps} from '@/components/base/modules/HeadingGroup/HeadingGroup';
import HeadingGroup from '@/components/base/modules/HeadingGroup/HeadingGroup';
import Link, {type LinkProps} from '@/components/base/elements/Link/Link';

import type {ChecklistProps} from '../Checklist/Checklist';
import Checklist from '../Checklist/Checklist';

interface SideBySideSectionProps extends ChecklistProps {
  headingGroup: HeadingGroupProps;
  link?: LinkProps;
}

export default function SideBySideChecklist({
  headingGroup,
  itemsHeading,
  itemsHtml,
  link,
  iconType = 'checkmark',
  mode = 'light',
  isCompact = false,
}: SideBySideSectionProps) {
  const checklistProps = {itemsHtml, itemsHeading, iconType, mode, isCompact};
  return (
    <TwoColumn>
      <TwoColumn.Col1>
        <HeadingGroup {...headingGroup} mode={mode} />
        {link && (
          <Link mode={mode} {...link}>
            {link.text}
          </Link>
        )}
      </TwoColumn.Col1>
      <TwoColumn.Col2 withGutter>
        <Checklist {...checklistProps} />
      </TwoColumn.Col2>
    </TwoColumn>
  );
}
